import { Box, styled } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const DashboardLayout = ({ sidebar, children }) => {
    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    px: 2,
                    pb: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor5})`,
                }}
                gap={2}
            >
                <DrawerHeader />
                {children}
            </Box>
        </>
    );
};

export default DashboardLayout;
