import { systemConfigSelector } from "../state/features/selectors";
import { useSelector } from "react-redux";

const useColor = () => {
    // ######### Inner states #############
    let color = null;

    // ######### Redux states #############
    const { darkMode } = useSelector(systemConfigSelector);

    const lightColor = {
        primary: "#445397",
        secondary: "#d00000",
        tertiary: "#ffba08",
        bgColor1: "#F9F9FB",
        bgColor2: "#F3F4F8",
        bgColor3: "#EDEFF5",
        bgColor4: "#E8EAF2",
        bgColor5: "#E2E4EE",
        orange: "#fca311",
        green: "#00EE76",
        red: "#F43249",
        gray: "#D9D9D9",
        blue: "#027BBD",
    };

    const darkColor = {
        primary: "#445397",
        secondary: "#d00000",
        tertiary: "#ffba08",
        bgColor1: "#F9F9FB",
        bgColor2: "#F3F4F8",
        bgColor3: "#EDEFF5",
        bgColor4: "#E8EAF2",
        bgColor5: "#E2E4EE",
        orange: "#fca311",
        green: "#00EE76",
        red: "#F43249",
        gray: "#D9D9D9",
        blue: "#027BBD",
    };

    if (darkMode) {
        color = darkColor;
    } else {
        color = lightColor;
    }
    return color;
};

export default useColor;
