import { Box, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import CustomTextField from "../../../components/forms/CustomTextField";
import CustomSubmitButton from "../../../components/forms/CustomSubmitButton";
import { Image, Save } from "@mui/icons-material";
import CustomFileInput from "../../../components/forms/CustomFileInput";
import { colors } from "../../../assets/utils/colors";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import useRTK from "../../../hooks/useRTK";
import { setArticles } from "../../../state/features/articles/articlesSlice";
import { useCreateArticlesMutation } from "../../../api/articles/articlesAPI";
import CustomMultlineTextField from "../../../components/forms/CustomMultlineTextField";

const createArticleValidationSchema = Yup.object({
    file: Yup.string().required(),
    title: Yup.string().required(),
    body: Yup.string().required(),
});

const CreateArticle = ({ handleClose }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp state ################
    const [filePreview, setFilePreview] = useState("");

    // ############# RTK ##################
    const [
        createArticle,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreateArticlesMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleClose();
        dispatch(setArticles(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        file: "",
                        title: "",
                        body: "",
                    }}
                    validationSchema={createArticleValidationSchema}
                    onSubmit={(values) => {
                        let payload = new FormData();
                        payload.append("image", values.file);
                        payload.append("title", values.title);
                        payload.append("body", values.body);
                        createArticle(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={"center"}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} sx={{ pt: 1 }}>
                                    <Grid container justifyContent={"center"}>
                                        <Grid item sm={6}>
                                            {filePreview ? (
                                                <>
                                                    <img
                                                        src={filePreview}
                                                        alt="img"
                                                        style={{
                                                            width: "100%",
                                                            height: "10em",
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <Image
                                                        sx={{
                                                            width: "100%",
                                                            height: "7em",
                                                            objectFit:
                                                                "contain",
                                                            opacity: 0.7,
                                                            color: colors.secondary,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item sm={6}>
                                            <Box
                                                sx={{
                                                    height: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <CustomFileInput
                                                    name="file"
                                                    type="file"
                                                    formik={formik}
                                                    accept="image/*"
                                                    setFilePreview={
                                                        setFilePreview
                                                    }
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="title"
                                        type={"text"}
                                        label={"Article title"}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomMultlineTextField
                                        name="body"
                                        type={"text"}
                                        label={"Article body"}
                                        rows={5}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={"primary"}
                                    >
                                        Save article
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateArticle;
