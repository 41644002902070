import { authAPI } from "../../api/auth/authAPI";
import { articlesAPI } from "../../api/articles/articlesAPI";
import { gallariesAPI } from "../../api/gallaries/gallariesAPI";
import { dailyReflectionAPI } from "../../api/dailyReflection/dailyReflectionAPI";

// => RTK middleware
export const RTKmiddleware = [
    authAPI.middleware,
    gallariesAPI.middleware,
    dailyReflectionAPI.middleware,
    articlesAPI.middleware,
];
