import { Grid } from "@mui/material";
import React from "react";
import DashInfobox from "../infoboxes/DashInfobox";
import {
    NewspaperSharp,
    PictureInPicture,
    LockClockOutlined,
} from "@mui/icons-material";
import {
    articlesSelector,
    dailyReflectionSelector,
    gallariesSelector,
} from "../../state/features/selectors";
import { size } from "lodash";
import { useSelector } from "react-redux";

const InfoBoxes = () => {
    // ############ Redux state #################
    const { articles } = useSelector(articlesSelector);
    const { gallaries } = useSelector(gallariesSelector);
    const { dailyReflections } = useSelector(dailyReflectionSelector);

    const infoBoxData = [
        {
            id: 1,
            title: "Gallery",
            total: size(gallaries),
            icon: <PictureInPicture className="icon" />,
            url: "/our-gallery",
        },
        {
            id: 2,
            title: "Articles",
            total: size(articles),
            icon: <NewspaperSharp className="icon" />,
            url: "/articles",
        },
        {
            id: 3,
            title: "Daily reflections",
            total: size(dailyReflections),
            icon: <LockClockOutlined className="icon" />,
            url: "/daily-reflections",
        },
    ];
    return (
        <>
            <Grid container spacing={2} sx={{ flex: 1, alignItems: "center" }}>
                {infoBoxData.map((data, index) => (
                    <Grid item xs key={index}>
                        <DashInfobox data={data} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default InfoBoxes;
