import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { gallariesEndpoints } from "./gallariesEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const gallariesAPI = createApi({
    reducerPath: "gallariesAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ["Gallaries"],
    endpoints: (builder) => ({
        getAllGallaries: builder.query({
            query: () => `${gallariesEndpoints.GET_ALL}`,
            providesTags: ["Gallaries"],
        }),
        getSingleGallary: builder.query({
            query: (payload) => `${gallariesEndpoints.GET_BY_ID}/${payload}`,
        }),
        createGallary: builder.mutation({
            query: (payload) => ({
                url: `${gallariesEndpoints.CREATE}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Gallaries"],
        }),
        updateGallary: builder.mutation({
            query: (payload) => ({
                url: `${gallariesEndpoints.UPDATE}/${payload.get(
                    "gallary_id"
                )}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Gallaries"],
        }),
        activateGallary: builder.mutation({
            query: (payload) => ({
                url: `${gallariesEndpoints.ACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["Gallaries"],
        }),
        deactivateGallary: builder.mutation({
            query: (payload) => ({
                url: `${gallariesEndpoints.DEACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["Gallaries"],
        }),
        deleteGallary: builder.mutation({
            query: (payload) => ({
                url: `${gallariesEndpoints.DELETE}/${payload}`,
                method: `DELETE`,
            }),
            invalidatesTags: ["Gallaries"],
        }),
    }),
});

export const {
    useGetAllGallariesQuery,
    useGetSingleGallaryQuery,
    useCreateGallaryMutation,
    useUpdateGallaryMutation,
    useActivateGallaryMutation,
    useDeactivateGallaryMutation,
    useDeleteGallaryMutation,

    // Lazy
    useLazyGetAllGallariesQuery,
} = gallariesAPI;
