import { useEffect } from "react";
import { errorNotify, successNotify } from "../helpers/notify";
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from "../helpers/RTKHelpers";
import { useDispatch, useSelector } from "react-redux";
import { setGallaries } from "../state/features/gallaries/gallariesSlice";
import { useLazyGetAllGallariesQuery } from "../api/gallaries/gallariesAPI";
import { isEmpty } from "lodash";
import { useLazyGetAllArticlesQuery } from "../api/articles/articlesAPI";
import { setArticles } from "../state/features/articles/articlesSlice";
import { setDailyReflections } from "../state/features/dailyReflection/dailyReflectionSlice";
import { useLazyGetAllDailyReflectionsQuery } from "../api/dailyReflection/dailyReflectionAPI";
import { disableMainFetch } from "../state/features/systemConfig/systemConfigSlice";
import { systemConfigSelector } from "../state/features/selectors";

const useRTK = () => {
    // ############# Redux state #############
    const { isMainFetch } = useSelector(systemConfigSelector);

    // => Use RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    successNotify(getRTKSuccessMessage(data));
                }
                if (isError) {
                    errorNotify(
                        !isEmpty(error)
                            ? getRTKErrorMessage(error)
                            : "Some error occur. Please try again!"
                    );
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => Use RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Gallaries #################
        const [
            getAllGallaries,
            {
                isLoading: gallariesLoading,
                isSuccess: gallariesSuccess,
                data: gallariesData,
            },
        ] = useLazyGetAllGallariesQuery();
        const rtkGallariesActions = (data) => {
            dispatch(setGallaries(data));
        };
        useRTKFetch(gallariesSuccess, gallariesData, rtkGallariesActions);

        // ################# Articles #################
        const [
            getAllArticles,
            {
                isLoading: articlesLoading,
                isSuccess: articlesSuccess,
                data: articlesData,
            },
        ] = useLazyGetAllArticlesQuery();
        const rtkArticlesActions = (data) => {
            dispatch(setArticles(data));
        };
        useRTKFetch(articlesSuccess, articlesData, rtkArticlesActions);

        // ################# Reflections #################
        const [
            getAllReflections,
            {
                isLoading: reflectionsLoading,
                isSuccess: reflectionsSuccess,
                data: reflectionsData,
            },
        ] = useLazyGetAllDailyReflectionsQuery();
        const rtkReflectionsActions = (data) => {
            dispatch(setDailyReflections(data));
        };
        useRTKFetch(reflectionsSuccess, reflectionsData, rtkReflectionsActions);

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isMainFetch) {
                    getAllGallaries();
                    getAllArticles();
                    getAllReflections();
                }
                setTimeout(() => {
                    dispatch(disableMainFetch());
                }, 2000);
            }
            return () => {
                isSubscribed = false;
            };
        }, []);

        return gallariesLoading
            ? gallariesLoading
            : articlesLoading
            ? articlesLoading
            : reflectionsLoading
            ? reflectionsLoading
            : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
