import React from "react";
import { DoubleArrowOutlined } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import logo from "../../assets/media/images/logo.png";
import { colors } from "../../assets/utils/colors";
import { Link } from "react-router-dom";
import moment from "moment";

const ArticleWidget = ({ article }) => {
    return (
        <>
            <Box
                sx={{
                    height: 130,
                    display: "flex",
                    borderRadius: 4,
                    overflow: "hidden",
                    boxShadow: `0px 0px 5px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        flex: 1.2,
                        borderRadius: 3,
                        background: `linear-gradient(180deg, ${colors.secondary}, ${colors.tertiary})`,
                    }}
                >
                    <Avatar
                        src={article?.image}
                        variant="square"
                        sx={{
                            width: "100%",
                            height: "100%",
                            p: 0.5,
                            img: { objectFit: "cover", borderRadius: 3 },
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flex: 2,
                        p: 1,
                        pl: 2,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor1})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            color: colors.primary,
                            textTransform: "uppercase",
                        }}
                    >
                        {article?.title}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 12,
                            my: 1,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}
                    >
                        {article?.body}
                    </Typography>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                                color: colors.blue,
                            }}
                        >
                            {moment(article?.created_at).format("LL")}
                        </Typography>
                        <Link to={`/articles/${article?.id}`}>
                            <IconButton
                                size="small"
                                color="info"
                                sx={{ float: "right" }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        mr: 1,
                                        fontWeight: "bold",
                                    }}
                                >
                                    read more
                                </Typography>
                                <DoubleArrowOutlined sx={{ fontSize: 15 }} />
                            </IconButton>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ArticleWidget;
