import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../assets/utils/colors";
import moment from "moment";

const DailyReflectionWidget = ({ reflection }) => {
    return (
        <>
            <Link to={`/daily-reflections/${reflection?.id}`}>
                <Box
                    sx={{
                        position: "relative",
                        ":before": {
                            content: "''",
                            width: "5%",
                            height: 3,
                            background: colors.primary,
                            position: "absolute",
                            top: 0,
                        },
                        ":after": {
                            content: "''",
                            width: "92%",
                            height: 3,
                            background: colors.bgColor3,
                            position: "absolute",
                            top: 0,
                            right: 0,
                        },
                        transition: `.3s`,
                        ":hover": {
                            transition: `.3s`,
                            boxShadow: `4px 4px 10px 0 ${colors.bgColor4}`,
                        },
                        boxShadow: `0 0 10px 0px silver`,
                    }}
                >
                    <Grid container>
                        <Grid item sm={2} xs={3}>
                            <Box
                                sx={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 35,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            color: colors.secondary,
                                        }}
                                    >
                                        {moment(reflection?.date).format("DD")}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                        }}
                                        noWrap
                                    >
                                        {moment(reflection?.date).format(
                                            "MMMM yy"
                                        )}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={10} xs={9}>
                            <Box sx={{ p: 3 }}>
                                <Typography
                                    sx={{
                                        fontSize: 15,
                                        fontWeight: "bold",
                                        mb: 1,
                                        opacity: 0.9,
                                        background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                        backgroundClip: "text",
                                        textFillColor: "transparent",
                                    }}
                                >
                                    {reflection?.title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 12,
                                        color: "gray",
                                    }}
                                >
                                    {reflection?.body}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Link>
        </>
    );
};

export default DailyReflectionWidget;
