import React from "react";
import {
    ArrowCircleRightOutlined,
    VerifiedUserOutlined,
} from "@mui/icons-material";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import logo from "../../assets/media/images/logo.png";
import CustomSubmitButton from "../../components/forms/CustomSubmitButton";
import HotToastfy from "../../components/HotToastfy";
import useAuth from "../../hooks/useAuth";
import { Formik } from "formik";
import CustomTextField from "../../components/forms/CustomTextField";
import { useLoginMutation } from "../../api/auth/authAPI";
import * as Yup from "yup";
import bgImg from "../../assets/media/images/priest.JPG";
import useRTK from "../../hooks/useRTK";

// ################### Validation ###############
const loginValidationSchema = Yup.object({
    phone_number: Yup.string().required("Agiza id is a required field"),
    password: Yup.string().required(),
});

const Login = () => {
    // ################### CONST #####################
    const auth = useAuth();
    const RTK = useRTK();

    // ############# RTK ##############
    const [login, { isLoading, isSuccess, isError, error, data: memberData }] =
        useLoginMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.login(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, memberData, rtkActions);

    return (
        <>
            <HotToastfy />
            <Grid container>
                <Grid item sm={12} xs={12} sx={{ position: "relative" }}>
                    <Avatar
                        src={bgImg}
                        sx={{
                            height: "100vh",
                            width: "100%",
                            opacity: { md: 0.6, xs: 0.1 },
                            zIndex: { md: 0, xs: 5 },
                            position: { md: "absolute", xs: "fixed" },
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                        }}
                        variant="square"
                    />
                    <Box
                        sx={{
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                            // background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor3})`,
                            position: { md: "static", xs: "fixed" },
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            zIndex: { md: 0, xs: 10 },
                        }}
                    >
                        <Grid
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={{ md: 10, xs: 0 }}
                        >
                            <Grid item sm={5} xs={12}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: { md: "100%", xs: "100vh" },
                                        p: { md: 5, xs: 2 },
                                        opacity: 0.9,
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        borderRadius: 10,
                                        zIndex: 50,
                                        boxShadow: `-5px 0px 5px 5px ${colors.tertiary}`,
                                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                mb: 4,
                                            }}
                                        >
                                            <Avatar
                                                src={logo}
                                                sx={{
                                                    height: 120,
                                                    width: 120,
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    ml: {
                                                        md: 1.5,
                                                        sm: 1.5,
                                                        xs: 0.5,
                                                    },
                                                    flex: 1,
                                                    height: 120,
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    p: 2,
                                                    borderRadius: 2,
                                                }}
                                                className="animated-bg"
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 15,
                                                            sm: 15,
                                                            xs: 10,
                                                        },
                                                        textTransform:
                                                            "capitalize",
                                                        color: colors.bgColor1,
                                                    }}
                                                    noWrap
                                                >
                                                    St. paul's
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 15,
                                                            sm: 15,
                                                            xs: 10,
                                                        },
                                                        textTransform:
                                                            "capitalize",
                                                        color: colors.bgColor1,
                                                    }}
                                                    noWrap
                                                >
                                                    Senior seminary
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 15,
                                                            sm: 15,
                                                            xs: 10,
                                                        },
                                                        textTransform:
                                                            "capitalize",
                                                        color: colors.bgColor1,
                                                    }}
                                                    noWrap
                                                >
                                                    Kipalapala - Tabora,
                                                    Tanzania
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            md: 15,
                                                            sm: 15,
                                                            xs: 10,
                                                        },
                                                        textTransform:
                                                            "uppercase",
                                                        color: colors.bgColor1,
                                                        textAlign: "center",
                                                        mt: 2,
                                                        background: `linear-gradient(${colors.bgColor1}, ${colors.tertiary})`,
                                                        backgroundClip: "text",
                                                        textFillColor:
                                                            "transparent",
                                                    }}
                                                    noWrap
                                                >
                                                    Admin dashboard
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt: {
                                                    md: 1,
                                                    xs: 3,
                                                },
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 22,
                                                    fontWeight: "bold",
                                                    color: colors.bgColor1,
                                                    background: `linear-gradient(${colors.bgColor1}, ${colors.tertiary})`,
                                                    backgroundClip: "text",
                                                    textFillColor:
                                                        "transparent",
                                                }}
                                            >
                                                Welcome
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 15,
                                                    color: colors.bgColor1,
                                                }}
                                            >
                                                Provide your credatial to sign
                                                in
                                            </Typography>
                                        </Box>
                                        <Box sx={{ mt: { md: 1, xs: 3 } }}>
                                            <Formik
                                                initialValues={{
                                                    // phone_number: "0732988533",
                                                    // password: "kipalapala@24",
                                                    phone_number: "",
                                                    password: "",
                                                }}
                                                validationSchema={
                                                    loginValidationSchema
                                                }
                                                onSubmit={(payload) => {
                                                    login(payload);
                                                }}
                                            >
                                                {(formik) => (
                                                    <Box className="input-card">
                                                        <Box sx={{ mb: 2 }}>
                                                            <Box>
                                                                <CustomTextField
                                                                    type="text"
                                                                    name="phone_number"
                                                                    label={
                                                                        "Phone number"
                                                                    }
                                                                    lableColor="#fff"
                                                                />
                                                                <CustomTextField
                                                                    type="password"
                                                                    name="password"
                                                                    label={
                                                                        "Password"
                                                                    }
                                                                    lableColor="#fff"
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                mt: 3,
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                                justifyContent:
                                                                    "center",
                                                                borderRight: `none`,
                                                                "& .head": {
                                                                    fontSize: 20,
                                                                    fontWeight:
                                                                        "bold",
                                                                    mb: 1,
                                                                },
                                                                "& .item": {
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    fontSize: 13,
                                                                    mb: 0.5,
                                                                    "& .icon": {
                                                                        mr: 1,
                                                                        color: colors.primary,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <CustomSubmitButton
                                                                loading={
                                                                    isLoading
                                                                }
                                                                onClick={
                                                                    formik.handleSubmit
                                                                }
                                                                startIcon={
                                                                    <VerifiedUserOutlined />
                                                                }
                                                                endIcon={
                                                                    <ArrowCircleRightOutlined />
                                                                }
                                                                btnColor={
                                                                    "secondary"
                                                                }
                                                            >
                                                                Sign in to
                                                                dashboard
                                                            </CustomSubmitButton>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Formik>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Login;
