import React from "react";
import CustomPagination from "../paginations/CustomPagination";
import { Link } from "react-router-dom";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { dailyReflectionSelector } from "../../state/features/selectors";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../NoContent";
import { isEmpty } from "lodash";
import DailyReflectionWidget from "../widgets/DailyReflectionWidget";

const LatestDailyReflection = () => {
    // ############### Component state ########################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############ Redux state #################
    const { dailyReflections } = useSelector(dailyReflectionSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Paginate data
    const paginatedReflections = paginator(dailyReflections, page, perPage);

    return (
        <>
            <CustomPagination
                title={"Latest posted reflection"}
                data={paginatedReflections}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                action={
                    <Link to={"/daily-reflections"}>
                        <Button
                            color="info"
                            size="small"
                            variant="outlined"
                            endIcon={<ArrowCircleRightOutlined />}
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                            }}
                        >
                            Manage
                        </Button>
                    </Link>
                }
            >
                <Grid container spacing={2} padding={2}>
                    {isEmpty(dailyReflections) ? (
                        <>
                            <NoContent
                                message={"No reflection added"}
                                height={200}
                            />
                        </>
                    ) : (
                        paginatedReflections.data?.map((reflection, index) => (
                            <Grid item sm={12} xs={12} key={index}>
                                <DailyReflectionWidget
                                    reflection={reflection}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomPagination>
        </>
    );
};

export default LatestDailyReflection;
