import { configureStore } from "@reduxjs/toolkit";
import { RTKmiddleware } from "./RTKmiddlewares";

// => SLICE reduces
import authReducer from "../features/auth/authSlice";
import gallariesReducer from "../features/gallaries/gallariesSlice";
import articlesReducer from "../features/articles/articlesSlice";
import dailyReflectionReducer from "../features/dailyReflection/dailyReflectionSlice";
import systemConfigReducer from "../features/systemConfig/systemConfigSlice";

// => RTK reducers
import { authAPI } from "../../api/auth/authAPI";
import { gallariesAPI } from "../../api/gallaries/gallariesAPI";
import { dailyReflectionAPI } from "../../api/dailyReflection/dailyReflectionAPI";
import { articlesAPI } from "../../api/articles/articlesAPI";

export const store = configureStore({
    reducer: {
        [authAPI.reducerPath]: authAPI.reducer,
        [gallariesAPI.reducerPath]: gallariesAPI.reducer,
        [dailyReflectionAPI.reducerPath]: dailyReflectionAPI.reducer,
        [articlesAPI.reducerPath]: articlesAPI.reducer,
        authReducer,
        gallariesReducer,
        articlesReducer,
        dailyReflectionReducer,
        systemConfigReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
