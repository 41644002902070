import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { useDate } from "../../hooks/useDate";
import { useSelector } from "react-redux";
import { authSelector } from "../../state/features/selectors";
import paul from "../../assets/media/images/paul.png";

const GreatingCard = () => {
    return (
        <>
            <Box
                sx={{
                    height: 100,
                    bgcolor: colors.primary,
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    borderRadius: 2,
                }}
                className="animated-bg"
            >
                <Box sx={{}}>
                    <Avatar src={paul} sx={{ height: 70, width: 70 }} />
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRadius: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 14,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            St. paul's
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 14,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            Senior seminary
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 14,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            Kipalapala - Tabora, Tanzania
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 14,
                                    sm: 14,
                                    xs: 10,
                                },
                                textTransform: "uppercase",
                                color: colors.bgColor1,
                                textAlign: "center",
                                background: `linear-gradient(${colors.bgColor1}, ${colors.tertiary})`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                                fontWeight: "bold",
                                mt: 1,
                            }}
                            noWrap
                        >
                            Admin dashboard
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{}}>
                    <Avatar src={paul} sx={{ height: 70, width: 70 }} />
                </Box>
            </Box>
        </>
    );
};

export default GreatingCard;
