import React from "react";
import { Box, Button } from "@mui/material";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import DashboardLayout from "./layouts/DashboardLayout";
import InfoBoxes from "../components/dash/InfoBoxes";
import GreatingCard from "../components/dash/GreatingCard";
import CustomPagination from "../components/paginations/CustomPagination";
import LatestDailyReflection from "../components/dash/LatestDailyReflection";
import LatestGallary from "../components/dash/LatestGallary";
import LatestNews from "../components/dash/LatestNews";

const Dashboard = () => {
    return (
        <>
            <DashboardLayout>
                <Box sx={{ flex: 1, display: "flex" }} gap={2}>
                    <Box
                        sx={{
                            flex: 1,
                            p: 1,
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                        }}
                    >
                        <GreatingCard />
                        <InfoBoxes />
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            position: "relative",
                        }}
                    >
                        <LatestDailyReflection />
                    </Box>
                </Box>
                <Box sx={{ flex: 1, display: "flex" }} gap={2}>
                    <Box sx={{ flex: 1, position: "relative" }}>
                        <LatestGallary />
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            position: "relative",
                        }}
                    >
                        <LatestNews />
                    </Box>
                </Box>
            </DashboardLayout>
        </>
    );
};

export default Dashboard;
