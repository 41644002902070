import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { dailyReflectionEndpoints } from "./dailyReflectionEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const dailyReflectionAPI = createApi({
    reducerPath: "dailyReflectionAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ["DailyReflections", "Reflection"],
    endpoints: (builder) => ({
        getAllDailyReflections: builder.query({
            query: () => `${dailyReflectionEndpoints.GET_ALL}`,
            providesTags: ["DailyReflections"],
        }),
        getSingleDailyReflections: builder.query({
            query: (payload) =>
                `${dailyReflectionEndpoints.GET_BY_ID}/${payload}`,
            providesTags: ["Reflection"],
        }),
        createDailyReflections: builder.mutation({
            query: (payload) => ({
                url: `${dailyReflectionEndpoints.CREATE}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["DailyReflections"],
        }),
        updateDailyReflections: builder.mutation({
            query: (payload) => ({
                url: `${dailyReflectionEndpoints.UPDATE}/${payload.get(
                    "reflection_id"
                )}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["DailyReflections", "Reflection"],
        }),
        activateDailyReflections: builder.mutation({
            query: (payload) => ({
                url: `${dailyReflectionEndpoints.ACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["DailyReflections", "Reflection"],
        }),
        deactivateDailyReflections: builder.mutation({
            query: (payload) => ({
                url: `${dailyReflectionEndpoints.DEACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["DailyReflections", "Reflection"],
        }),
        deleteDailyReflections: builder.mutation({
            query: (payload) => ({
                url: `${dailyReflectionEndpoints.DELETE}/${payload}`,
                method: `DELETE`,
            }),
            invalidatesTags: ["DailyReflections", "Reflection"],
        }),
    }),
});

export const {
    useGetAllDailyReflectionsQuery,
    useGetSingleDailyReflectionsQuery,
    useCreateDailyReflectionsMutation,
    useUpdateDailyReflectionsMutation,
    useActivateDailyReflectionsMutation,
    useDeactivateDailyReflectionsMutation,
    useDeleteDailyReflectionsMutation,

    // Lazy
    useLazyGetAllDailyReflectionsQuery,
} = dailyReflectionAPI;
