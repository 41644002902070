import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { RemoveRedEyeOutlined } from "@mui/icons-material";

const GalleryWidget = ({ gallery, handlePreview }) => {
    return (
        <>
            <Box
                sx={{
                    background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                    position: "relative",
                    ":before": {
                        content: "''",
                        height: 50,
                        width: 50,
                        position: "absolute",
                        left: -5,
                        bottom: -5,
                        background: `linear-gradient(360deg, ${colors.primary}, ${colors.secondary})`,
                        transition: ".5s",
                        borderRadius: 5,
                    },
                    ":after": {
                        content: "''",
                        height: 50,
                        width: 50,
                        // background: `linear-gradient(360deg, ${colors.primary}, ${colors.secondary})`,
                        position: "absolute",
                        right: -5,
                        top: -5,
                        zIndex: 5,
                        transition: ".5s",
                        borderRadius: 2,
                    },
                    ".preview": {
                        transition: ".5s",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        height: "0%",
                        width: "0%",
                        zIndex: 999,
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        overflow: "hidden",
                        background: `rgba(0,0,0,0.8)`,
                    },
                    ":hover": {
                        ".image": {
                            transition: ".5s",
                            transform: `scale(1.1)`,
                        },
                        ".preview": {
                            height: "100%",
                            width: "100%",
                            transition: ".5s",
                        },
                        ":before": {
                            content: "''",
                            height: "100%",
                            width: "100%",
                            transition: ".5s",
                        },
                        // ":after": {
                        //     content: "''",
                        //     height: "100%",
                        //     width: "100%",
                        //     transition: ".5s",
                        // },
                    },
                }}
            >
                <Box
                    sx={{
                        borderRadius: 5,
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <Box className="preview">
                        <Typography
                            sx={{
                                color: "#fff",
                                fontSize: 12,
                                fontWeight: "bold",
                            }}
                        >
                            {gallery.title}
                        </Typography>
                        <IconButton
                            size="small"
                            sx={{
                                mt: 2,
                                px: 1,
                                background: `linear-gradient(360deg, ${colors.primary}, ${colors.secondary})`,
                            }}
                            onClick={handlePreview}
                        >
                            <RemoveRedEyeOutlined
                                sx={{ fontSIze: 12, color: "#FFF" }}
                            />
                        </IconButton>
                    </Box>
                    <Avatar
                        src={gallery.image}
                        variant="square"
                        className="image"
                        sx={{
                            zIndex: 15,
                            transition: ".5s",
                            height: {
                                md: "20vh",
                                sm: "20vh",
                                xs: "30vh",
                            },
                            width: `100%`,
                            img: {
                                objectFit: "cover",
                            },
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default GalleryWidget;
