import React from "react";
import { Box, FormControl, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, useField } from "formik";
import { colors } from "../../assets/utils/colors";

const CustomInput = styled(TextField)({});

const CustomTextField = ({ label, lableColor, type, value, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box sx={{ marginTop: 2 }}>
                <FormControl size="small" fullWidth>
                    <CustomInput
                        error={meta.touched && meta.error ? true : false}
                        label={label}
                        variant="filled"
                        fullWidth
                        type={type}
                        placeholder={label}
                        {...field}
                        {...props}
                        size="small"
                        sx={{
                            "& .MuiFormLabel-root": {
                                color: lableColor ? lableColor : colors.primary,
                            },
                        }}
                        focused={
                            type === "date" || type === "time" ? true : false
                        }
                    />
                </FormControl>
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomTextField;
