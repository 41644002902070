// => GET all articles
const GET_ALL = "/articles";

// => GET single article
const GET_BY_ID = "/articles";

// => CREATE article
const CREATE = "/articles";

// => UPDATE article
const UPDATE = "/articles";

// => ACTIVATE article
const ACTIVATE = "/articles/activate";

// => DEACTIVATE article
const DEACTIVATE = "/articles/deactivate";

// => DELETE article
const DELETE = "/articles";

export const articleEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
