// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Gallary selector
export const gallariesSelector = (state) => state.gallariesReducer;

// => articles selector
export const articlesSelector = (state) => state.articlesReducer;

// => daily reflection selector
export const dailyReflectionSelector = (state) => state.dailyReflectionReducer;

// => systemConfig selector
export const systemConfigSelector = (state) => state.systemConfigReducer;
