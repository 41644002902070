import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import {
    articlesSelector,
    dailyReflectionSelector,
} from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import { useParams } from "react-router-dom";
import UpdateArticle from "./CRUD/UpdateArticle";
import { setArticleDetails } from "../../state/features/articles/articlesSlice";
import { useGetSingleArticlesQuery } from "../../api/articles/articlesAPI";
import DeleteArticle from "./CRUD/DeleteArticle";
import ActivateDeactivateArticle from "./CRUD/ActivateDeactivateArticle";
import { EditTwoTone } from "@mui/icons-material";
import moment from "moment";

const ArticleDetailsPage = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { article_id } = useParams();

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############ Redux state #################
    const { articleDetails } = useSelector(articlesSelector);

    // ############# FUNC ###############
    const handleDialogyOpen = (type) => {
        setDialogOpen(true);
    };
    const handleDialogyClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        data: responseData,
    } = useGetSingleArticlesQuery(article_id);

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setArticleDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* ########### Dialog ############## */}
            <CustomDialog
                head={"Update article"}
                openDialog={dialogOpen}
                closeDialog={handleDialogyClose}
            >
                <UpdateArticle
                    eventType={articleDetails}
                    handleClose={handleDialogyClose}
                />
            </CustomDialog>

            <PageLayout pageTitle={"Article details"} isLoading={isLoading}>
                {isEmpty(articleDetails) ? (
                    <>
                        <NoContent message={"Article details not found"} />
                    </>
                ) : (
                    <Box
                        sx={{
                            background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                            borderRadius: 5,
                            p: 2,
                        }}
                    >
                        <Grid container spacing={4}>
                            <Grid item sm={6} xs={12}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        position: "relative",
                                        display: "flex",
                                    }}
                                >
                                    <Box
                                        className="img1"
                                        sx={{
                                            height: 400,
                                            width: "100%",
                                            position: "relative",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Avatar
                                            src={articleDetails?.image}
                                            variant="square"
                                            sx={{
                                                height: "100%",
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                            opacity: 0.8,
                                        }}
                                    >
                                        {articleDetails?.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            lineHeight: 1.6,
                                            opacity: 0.8,
                                            background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                            backgroundClip: "text",
                                            textFillColor: "transparent",
                                            my: 2,
                                        }}
                                    >
                                        {articleDetails?.body}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: colors.blue,
                                        }}
                                    >
                                        Posted On :{" "}
                                        {moment(
                                            articleDetails?.created_at
                                        ).format("LL")}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                <Box
                                    sx={{
                                        height: 50,
                                        mt: -1,
                                        borderBottomLeftRadius: 10,
                                        borderBottomRightRadius: 10,
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        pt: 1,
                                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <ActivateDeactivateArticle
                                        status={articleDetails.status}
                                        id={articleDetails.id}
                                    />
                                    <IconButton
                                        size="small"
                                        sx={{
                                            p: 1,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        }}
                                        onClick={() => {
                                            handleDialogyOpen();
                                        }}
                                    >
                                        <EditTwoTone
                                            color="warning"
                                            sx={{ fontSize: 20 }}
                                        />
                                    </IconButton>
                                    <DeleteArticle article={articleDetails} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </PageLayout>
        </>
    );
};

export default ArticleDetailsPage;
