import React from "react";
import { Box, Grid, IconButton, Typography, styled } from "@mui/material";
import { ArrowCircleLeft, Window } from "@mui/icons-material";
import { colors } from "../../assets/utils/colors";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../components/PageLoader";

const DrawerHeader = styled(Box)(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const PageLayout = ({ children, sidebar, pageTitle, isLoading }) => {
    const navigate = useNavigate();
    return (
        <>
            {/* <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.bgColor5})`,
                }}
            >
                <DrawerHeader />
                <Grid
                    container
                    sx={{
                        position: { md: "sticky", xs: "fixed" },
                        zIndex: 999,
                    }}
                >
                    <Grid item sm={12} xs={12}>
                        <Box
                            sx={{
                                p: 2,
                                height: 68,
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    opacity: 0.7,
                                }}
                            >
                                <Window sx={{ mr: 1 }} color="secondary" />
                                {pageTitle}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                <ArrowCircleLeft
                                    sx={{ fontSize: 35 }}
                                    color="secondary"
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                {children}
                {sidebar && (
                    <Grid
                        item
                        sm={3.5}
                        xs={12}
                        sx={{
                            position: "relative",
                            height: { md: "100vh", xs: "100%" },
                        }}
                    >
                        <Box
                            sx={{
                                top: 0,
                                position: {
                                    md: "sticky",
                                    xs: "relative",
                                },
                                display: {
                                    md: "block",
                                    xs: "none",
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    position: {
                                        md: "absolute",
                                        xs: "relative",
                                    },
                                    top: 0,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    height: {
                                        md: "100vh",
                                        xs: "100%",
                                    },
                                    overflow: "auto",
                                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                }}
                            >
                                <DrawerHeader />
                                {sidebar}
                            </Box>
                        </Box>
                    </Grid>
                )}
            </Box> */}
            <Box sx={{ position: "relative" }}>
                {isLoading ? (
                    <>
                        <Box
                            sx={{
                                position: "relative",
                                height: "100vh",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <PageLoader />
                        </Box>
                    </>
                ) : (
                    <>
                        <Grid container>
                            <Grid
                                item
                                sm={sidebar ? 8.5 : 12}
                                xs={12}
                                sx={{ position: "relative", height: "100vh" }}
                            >
                                <DrawerHeader />
                                <Grid
                                    container
                                    sx={{
                                        position: { md: "sticky", xs: "fixed" },
                                        top: { md: 64, xs: 60 },
                                        zIndex: 999,
                                    }}
                                >
                                    <Grid item sm={12} xs={12}>
                                        <Box
                                            sx={{
                                                p: 2,
                                                height: 68,
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor5})`,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    opacity: 0.7,
                                                }}
                                            >
                                                <Window
                                                    sx={{ mr: 1 }}
                                                    color="secondary"
                                                />
                                                {pageTitle}
                                            </Typography>
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    navigate(-1);
                                                }}
                                            >
                                                <ArrowCircleLeft
                                                    sx={{ fontSize: 35 }}
                                                    color="secondary"
                                                />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {children}
                            </Grid>
                            {sidebar && (
                                <Grid
                                    item
                                    sm={3.5}
                                    xs={12}
                                    sx={{
                                        position: "relative",
                                        height: { md: "100vh", xs: "100%" },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            top: 0,
                                            position: {
                                                md: "sticky",
                                                xs: "relative",
                                            },
                                            display: {
                                                md: "block",
                                                xs: "none",
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: {
                                                    md: "absolute",
                                                    xs: "relative",
                                                },
                                                top: 0,
                                                bottom: 0,
                                                right: 0,
                                                left: 0,
                                                height: {
                                                    md: "100vh",
                                                    xs: "100%",
                                                },
                                                overflow: "auto",
                                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                                            }}
                                        >
                                            <DrawerHeader />
                                            {sidebar}
                                        </Box>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Box>
        </>
    );
};

export default PageLayout;
