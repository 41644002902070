import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../baseUrl/baseURL";
import { articleEndpoints } from "./articleEndpoints";
import { headers } from "../baseUrl/setHeaders";

export const articlesAPI = createApi({
    reducerPath: "articlesAPI",
    baseQuery: fetchBaseQuery({ baseUrl: baseURL, prepareHeaders: headers }),
    tagTypes: ["Articles", "Article"],
    endpoints: (builder) => ({
        getAllArticles: builder.query({
            query: () => `${articleEndpoints.GET_ALL}`,
            providesTags: ["Articles"],
        }),
        getSingleArticles: builder.query({
            query: (payload) => `${articleEndpoints.GET_BY_ID}/${payload}`,
            providesTags: ["Article"],
        }),
        createArticles: builder.mutation({
            query: (payload) => ({
                url: `${articleEndpoints.CREATE}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Articles"],
        }),
        updateArticles: builder.mutation({
            query: (payload) => ({
                url: `${articleEndpoints.UPDATE}/${payload.get("article_id")}`,
                method: `POST`,
                body: payload,
            }),
            invalidatesTags: ["Articles", "Article"],
        }),
        activateArticles: builder.mutation({
            query: (payload) => ({
                url: `${articleEndpoints.ACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["Articles", "Article"],
        }),
        deactivateArticles: builder.mutation({
            query: (payload) => ({
                url: `${articleEndpoints.DEACTIVATE}/${payload}`,
                method: `PUT`,
            }),
            invalidatesTags: ["Articles", "Article"],
        }),
        deleteArticles: builder.mutation({
            query: (payload) => ({
                url: `${articleEndpoints.DELETE}/${payload}`,
                method: `DELETE`,
            }),
            invalidatesTags: ["Articles", "Article"],
        }),
    }),
});

export const {
    useGetAllArticlesQuery,
    useGetSingleArticlesQuery,
    useCreateArticlesMutation,
    useUpdateArticlesMutation,
    useActivateArticlesMutation,
    useDeactivateArticlesMutation,
    useDeleteArticlesMutation,

    // Lazy
    useLazyGetAllArticlesQuery,
} = articlesAPI;
