import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    gallaries: [],
};

// !########## SLICE #############
const gallariesSlice = createSlice({
    name: 'gallariesSlice',
    initialState,
    reducers: {
        setGallaries: (state, actions) => {
            state.gallaries = actions.payload;
        },
    },
});

export const { setGallaries } = gallariesSlice.actions;
export default gallariesSlice.reducer;
