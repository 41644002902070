// => Login
const LOGIN = "/auth/login";

// => Logout
const LOGOUT = "/auth/logout";

export const authEndpoints = {
    LOGIN,
    LOGOUT,
};
