import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    dailyReflections: [],
    reflectionDetails: null,
};

// !########## SLICE #############
const dailyReflectionSlice = createSlice({
    name: "dailyReflectionSlice",
    initialState,
    reducers: {
        setDailyReflections: (state, actions) => {
            state.dailyReflections = actions.payload;
        },
        setDailyReflectionDetails: (state, actions) => {
            state.reflectionDetails = actions.payload;
        },
    },
});

export const { setDailyReflections, setDailyReflectionDetails } =
    dailyReflectionSlice.actions;
export default dailyReflectionSlice.reducer;
