import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import {
    Box,
    Button,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { EditTwoTone, ReadMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { gallariesSelector } from "../../state/features/selectors";
import { useGetAllGallariesQuery, useLazyGetAllGallariesQuery } from "../../api/gallaries/gallariesAPI";
import { setGallaries } from "../../state/features/gallaries/gallariesSlice";
import CustomDialog from "../../components/dialogs/CustomDialog";
import CreateGallary from "./CRUD/CreateGallary";
import DeleteGallary from "./CRUD/DeleteGallary";
import ActivateDeactivateGallary from "./CRUD/ActivateDeactivateGallary";
import UpdateGallary from "./CRUD/UpdateGallary";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import GallaryView from "../../components/GallaryView";

const OurGalleryPage = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const [actionType, setActionType] = useState("");
    const [gallaryDetails, setGallaryDetails] = useState({});
    const [gallaryView, setGallaryView] = React.useState(null);
    const [toggler, setToggler] = React.useState(false);
    const deviceWidth = useMediaQuery("(min-width:600px)");

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############ Redux state #################
    const { gallaries } = useSelector(gallariesSelector);

    // ############# FUNC ###############
    const handleGallaryView = (gallary) => {
        setToggler(!toggler);
        setGallaryView(gallary);
    };
    const handleDialogyOpen = (type) => {
        setActionType(type);
        setDialogOpen(true);
    };
    const handleDialogyClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    // const {
    //     isLoading,
    //     isSuccess,
    //     data: responseData,
    // } = useLazyGetAllGallariesQuery();

    // ############# RTK response ##################
    // const rtkActions = (data) => {
    //     dispatch(setGallaries(data));
    // };
    // RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            <GallaryView toggler={toggler} gallary={gallaryView} />
            {/* ########### Dialog ############## */}
            <CustomDialog
                // customWidth={"md"}
                head={
                    actionType === "create"
                        ? "Create new gallary"
                        : "Update gallary"
                }
                openDialog={dialogOpen}
                closeDialog={handleDialogyClose}
            >
                {actionType === "create" ? (
                    <CreateGallary handleClose={handleDialogyClose} />
                ) : (
                    <UpdateGallary
                        gallary={gallaryDetails}
                        handleClose={handleDialogyClose}
                    />
                )}
            </CustomDialog>

            <PageLayout
                pageTitle={"Gallaries managements"}
                // isLoading={isLoading}
            >
                <Box
                    sx={{
                        width: 180,
                        position: "fixed",
                        right: { md: 15, xs: 10 },
                        bottom: { md: 15, xs: 10 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        overflow: "hidden",
                        zIndex: 999,
                        border: `2px dotted ${colors.secondary}`,
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            let type = "create";
                            handleDialogyOpen(type);
                        }}
                        sx={{
                            py: { md: 2, xs: 1 },
                            fontWeight: "bold",
                            fontSize: 14,
                        }}
                    >
                        Add new gallary
                    </Button>
                </Box>
                {isEmpty(gallaries) ? (
                    <>
                        <NoContent message={"No gallary added"} />
                    </>
                ) : (
                    <Grid container paddingX={3}>
                        <Grid
                            item
                            sm={12}
                            sx={{
                                mb: { md: 15, xs: 10 },
                                mt: { md: 0, xs: 10 },
                            }}
                        >
                            <ImageList gap={15} cols={deviceWidth ? 4 : 2}>
                                {gallaries.map((gallary, index) => (
                                    <Box sx={{}} key={index}>
                                        <ImageListItem
                                            key={index}
                                            sx={{
                                                borderRadius: { md: 2, xs: 2 },
                                                overflow: "hidden",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                handleGallaryView(gallary)
                                            }
                                        >
                                            <img
                                                src={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                                srcSet={`${gallary.image}?w=164&h=164&fit=crop&auto=format`}
                                                alt={gallary.event}
                                                loading="lazy"
                                                style={{
                                                    height: 200,
                                                }}
                                            />
                                            <ImageListItemBar
                                                title={
                                                    <Typography
                                                        noWrap
                                                        sx={{ fontSize: 13 }}
                                                    >
                                                        {gallary.title}
                                                    </Typography>
                                                }
                                                actionIcon={
                                                    <IconButton
                                                        sx={{
                                                            color: "rgba(255, 255, 255, 0.54)",
                                                        }}
                                                        onClick={() =>
                                                            handleGallaryView(
                                                                gallary
                                                            )
                                                        }
                                                    >
                                                        <ReadMore color="secondary" />
                                                    </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                        <Box
                                            sx={{
                                                height: 50,
                                                mt: -1,
                                                borderBottomLeftRadius: 10,
                                                borderBottomRightRadius: 10,
                                                display: "flex",
                                                justifyContent: "space-around",
                                                alignItems: "center",
                                                pt: 1,
                                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                                            }}
                                        >
                                            <ActivateDeactivateGallary
                                                status={gallary.status}
                                                id={gallary.id}
                                            />
                                            <IconButton
                                                size="small"
                                                sx={{
                                                    p: 1,
                                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                }}
                                            >
                                                <EditTwoTone
                                                    color="warning"
                                                    sx={{ fontSize: 20 }}
                                                    onClick={() => {
                                                        let type = "update";
                                                        setGallaryDetails(
                                                            gallary
                                                        );
                                                        handleDialogyOpen(type);
                                                    }}
                                                />
                                            </IconButton>
                                            <DeleteGallary gallary={gallary} />
                                        </Box>
                                    </Box>
                                ))}
                            </ImageList>
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default OurGalleryPage;
