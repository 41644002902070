import React from "react";
import CustomPagination from "../paginations/CustomPagination";
import { Link } from "react-router-dom";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { articlesSelector } from "../../state/features/selectors";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../NoContent";
import { isEmpty } from "lodash";
import ArticleWidget from "../widgets/ArticleWidget";

const LatestNews = () => {
    // ############### Component state ########################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);

    // ############ Redux state #################
    const { articles } = useSelector(articlesSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Paginate data
    const paginatedArticles = paginator(articles, page, perPage);

    return (
        <>
            <CustomPagination
                title={"Latest posted articles"}
                data={paginatedArticles}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                action={
                    <Link to={"/articles"}>
                        <Button
                            color="info"
                            size="small"
                            variant="outlined"
                            endIcon={<ArrowCircleRightOutlined />}
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                            }}
                        >
                            Manage
                        </Button>
                    </Link>
                }
            >
                <Grid container spacing={2} padding={2}>
                    {isEmpty(articles) ? (
                        <>
                            <NoContent
                                message={"No article added"}
                                height={200}
                            />
                        </>
                    ) : (
                        paginatedArticles.data?.map((article, index) => (
                            <Grid item sm={12} xs={12} key={index}>
                                <ArticleWidget article={article} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomPagination>
        </>
    );
};

export default LatestNews;
