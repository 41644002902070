import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { EditTwoTone } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { articlesSelector } from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import EventTypeWidget from "../../components/widgets/EventTypeWidget";
import UpdateArticle from "./CRUD/UpdateArticle";
import CreateArticle from "./CRUD/CreateArticle";
import ActivateDeactivateArticle from "./CRUD/ActivateDeactivateArticle";
import DeleteArticle from "./CRUD/DeleteArticle";
import { useGetAllArticlesQuery } from "../../api/articles/articlesAPI";
import { setArticles } from "../../state/features/articles/articlesSlice";
import ArticleWidget from "../../components/widgets/ArticleWidget";

const ArticlePage = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############ Redux state #################
    const { articles } = useSelector(articlesSelector);

    // ############# FUNC ###############
    const handleDialogyOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogyClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    // const {
    //     isLoading,
    //     isSuccess,
    //     data: responseData,
    // } = useGetAllArticlesQuery();

    // ############# RTK response ##################
    // const rtkActions = (data) => {
    //     dispatch(setArticles(data));
    // };
    // RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* ########### Dialog ############## */}
            <CustomDialog
                head={"Create article"}
                openDialog={dialogOpen}
                closeDialog={handleDialogyClose}
            >
                <CreateArticle handleClose={handleDialogyClose} />
            </CustomDialog>

            <PageLayout
                pageTitle={"Articles managements"}
                // isLoading={isLoading}
            >
                <Box
                    sx={{
                        width: 180,
                        position: "fixed",
                        right: { md: 15, xs: 10 },
                        bottom: { md: 15, xs: 10 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        overflow: "hidden",
                        zIndex: 999,
                        border: `2px dotted ${colors.secondary}`,
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            handleDialogyOpen();
                        }}
                        sx={{
                            py: { md: 2, xs: 1 },
                            fontWeight: "bold",
                            fontSize: 14,
                        }}
                    >
                        Add article
                    </Button>
                </Box>
                {isEmpty(articles) ? (
                    <>
                        <NoContent message={"No article added"} />
                    </>
                ) : (
                    <Grid
                        container
                        paddingX={3}
                        spacing={3}
                        sx={{
                            mb: { md: 15, xs: 10 },
                            mt: { md: 0, xs: 10 },
                        }}
                    >
                        {articles.map((article, index) => (
                            <Grid item sm={4} key={article.id}>
                                <ArticleWidget
                                    article={article}
                                    index={index}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default ArticlePage;
