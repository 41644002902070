import React from "react";
import { Box, Grid } from "@mui/material";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/colors";

const PageLoader = () => {
    return (
        <>
            <Box sx={{ flex: 1 }}>
                <Loader
                    type="bubble-ping"
                    bgColor={colors.primary}
                    color={colors.primary}
                    size={100}
                />
            </Box>
        </>
    );
};

export default PageLoader;
