// => GET all daily reflections
const GET_ALL = "/daily-reflections";

// => GET single daily reflection
const GET_BY_ID = "/daily-reflections";

// => CREATE daily reflection
const CREATE = "/daily-reflections";

// => UPDATE daily reflection
const UPDATE = "/daily-reflections";

// => ACTIVATE daily reflection
const ACTIVATE = "/daily-reflections/activate";

// => DEACTIVATE daily reflection
const DEACTIVATE = "/daily-reflections/deactivate";

// => DELETE daily reflection
const DELETE = "/daily-reflections";

export const dailyReflectionEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
