import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Box, Button, Grid } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { dailyReflectionSelector } from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import UpdateReflection from "./CRUD/UpdateReflection";
import CreateReflection from "./CRUD/CreateReflection";
import { useGetAllDailyReflectionsQuery } from "../../api/dailyReflection/dailyReflectionAPI";
import { setDailyReflections } from "../../state/features/dailyReflection/dailyReflectionSlice";
import DailyReflectionWidget from "../../components/widgets/DailyReflectionWidget";

const DailyReflectionsPage = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const [actionType, setActionType] = useState("");
    const [reflectionDetails, setReflectionDetails] = useState({});

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############ Redux state #################
    const { dailyReflections } = useSelector(dailyReflectionSelector);

    // ############# FUNC ###############
    const handleDialogyOpen = (type) => {
        setActionType(type);
        setDialogOpen(true);
    };
    const handleDialogyClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    // const {
    //     isLoading,
    //     isSuccess,
    //     data: responseData,
    // } = useGetAllDailyReflectionsQuery();

    // ############# RTK response ##################
    // const rtkActions = (data) => {
    //     dispatch(setDailyReflections(data));
    // };
    // RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* ########### Dialog ############## */}
            <CustomDialog
                head={
                    actionType === "create"
                        ? "Create article"
                        : "Update article"
                }
                openDialog={dialogOpen}
                closeDialog={handleDialogyClose}
            >
                {actionType === "create" ? (
                    <CreateReflection handleClose={handleDialogyClose} />
                ) : (
                    <UpdateReflection
                        eventType={reflectionDetails}
                        handleClose={handleDialogyClose}
                    />
                )}
            </CustomDialog>

            <PageLayout
                pageTitle={"Daily reflections managements"}
                // isLoading={isLoading}
            >
                <Box
                    sx={{
                        width: 180,
                        position: "fixed",
                        right: { md: 15, xs: 10 },
                        bottom: { md: 15, xs: 10 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 5,
                        overflow: "hidden",
                        zIndex: 999,
                        border: `2px dotted ${colors.secondary}`,
                    }}
                >
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            let type = "create";
                            handleDialogyOpen(type);
                        }}
                        sx={{
                            py: { md: 2, xs: 1 },
                            fontWeight: "bold",
                            fontSize: 14,
                        }}
                    >
                        Add reflection
                    </Button>
                </Box>
                {isEmpty(dailyReflections) ? (
                    <>
                        <NoContent message={"No reflection added"} />
                    </>
                ) : (
                    <Grid
                        container
                        paddingX={3}
                        spacing={3}
                        sx={{
                            mb: { md: 15, xs: 10 },
                            mt: { md: 0, xs: 10 },
                        }}
                    >
                        {dailyReflections.map((reflection, index) => (
                            <Grid item sm={6} key={reflection.id}>
                                <DailyReflectionWidget
                                    reflection={reflection}
                                    index={index}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default DailyReflectionsPage;
