const env = "pro";

// => Dev base url
const devBaseUrl = "http://127.0.0.1:8000/api/v1";

// => Pro base url
const proBaseUrl = "https://secure.stpaulsseminary.ac.tz/api/v1";

const getBaseURL = () => {
    if (env === "pro") {
        return proBaseUrl;
    }
    return devBaseUrl;
};

export const baseURL = getBaseURL();
