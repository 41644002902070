import {
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets/utils/colors";
import { ArrowCircleRightOutlined, ArrowRight } from "@mui/icons-material";
import { Link } from "react-router-dom";

const DashInfobox = ({ data }) => {
    return (
        <>
            <Box
                sx={{
                    height: 100,
                    bgcolor: "#fff",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    p: 1.5,
                    boxShadow: `0px 0px 10px ${colors.bgColor5}`,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        ".icon": {
                            fontSize: 25,
                            color: colors.secondary,
                        },
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    {data.icon}
                    <Typography
                        sx={{
                            fontWeight: "bold",
                            height: 30,
                            width: 30,
                            borderRadius: 50,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: 12,
                            mr: 0.2,
                            mt: -0.5,
                            background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                        }}
                    >
                        {data.total}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ fontSize: 13, fontWeight: "bold" }}>
                        {data.title}
                    </Typography>
                    <Tooltip title={"Manage"} arrow>
                        <Link to={data.url}>
                            <IconButton size="small" color="info">
                                <ArrowCircleRightOutlined />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </Box>
            </Box>
        </>
    );
};

export default DashInfobox;
