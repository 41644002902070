import React from "react";
import CustomPagination from "../paginations/CustomPagination";
import { Link } from "react-router-dom";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { gallariesSelector } from "../../state/features/selectors";
import { paginator } from "../../helpers/paginationHelper";
import NoContent from "../NoContent";
import { isEmpty } from "lodash";
import GalleryWidget from "../widgets/GalleryWidget";
import GallaryView from "../GallaryView";

const LatestGallary = () => {
    // ############### Component state ########################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(25);
    const [toggler, setToggler] = React.useState(false);
    const [gallaryView, setGallaryView] = React.useState(null);

    // ############ Redux state #################
    const { gallaries } = useSelector(gallariesSelector);

    // ############### FUNC ###########################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Gallary preview
    const handleGallaryView = (gallary) => {
        setToggler(!toggler);
        setGallaryView(gallary);
    };
    // => Paginate data
    const paginatedGallary = paginator(gallaries, page, perPage);

    return (
        <>
            <GallaryView toggler={toggler} gallary={gallaryView} />;
            <CustomPagination
                title={"Latest posted gallery"}
                data={paginatedGallary}
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                action={
                    <Link to={"/our-gallery"}>
                        <Button
                            color="info"
                            size="small"
                            variant="outlined"
                            endIcon={<ArrowCircleRightOutlined />}
                            sx={{
                                fontSize: 12,
                                fontWeight: "bold",
                            }}
                        >
                            Manage
                        </Button>
                    </Link>
                }
            >
                <Grid container spacing={2} padding={2}>
                    {isEmpty(gallaries) ? (
                        <>
                            <NoContent
                                message={"No gallary added"}
                                height={200}
                            />
                        </>
                    ) : (
                        paginatedGallary.data?.map((gallary, index) => (
                            <Grid item sm={4} xs={12} key={index}>
                                <GalleryWidget
                                    gallery={gallary}
                                    handlePreview={() => {
                                        handleGallaryView(gallary);
                                    }}
                                />
                            </Grid>
                        ))
                    )}
                </Grid>
            </CustomPagination>
        </>
    );
};

export default LatestGallary;
