import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/Dashboard";
import AppLayout from "../pages/layouts/AppLayout";
import GuestRoutes from "./GuestRoutes";
import PrivateRoutes from "./PrivateRoutes";
import OurGalleryPage from "../pages/ourGallery/OurGalleryPage";
import DailyReflectionsPage from "../pages/dailyReflections/DailyReflectionsPage";
import ArticlePage from "../pages/articles/ArticlePage";
import SystemConfigPage from "../pages/systemConfiguration/SystemConfigPage";
import ProfilePage from "../pages/auth/ProfilePage";
import ArticleDetailsPage from "../pages/articles/ArticleDetailsPage";
import DailyReflectionDetailsPage from "../pages/dailyReflections/DailyReflectionDetailsPage";

// ?############## GUEST ELEMENTS ###########
const LoginElement = () => <Login />;

// ?############## PROTECTED ELEMENTS ###########
// => Dashboard
const DashboardElement = () => (
    <AppLayout>
        <Dashboard />
    </AppLayout>
);
// => Our Gallery
const OurGalleryElement = () => (
    <AppLayout>
        <OurGalleryPage />
    </AppLayout>
);
// => Daily Reflection
const DailyReflectionsElement = () => (
    <AppLayout>
        <DailyReflectionsPage />
    </AppLayout>
);
// => Daily Reflection detrails
const DailyReflectionDetailsElement = () => (
    <AppLayout>
        <DailyReflectionDetailsPage />
    </AppLayout>
);
// => Article
const ArticlesElement = () => (
    <AppLayout>
        <ArticlePage />
    </AppLayout>
);
// => Article details
const ArticleDetailsElement = () => (
    <AppLayout>
        <ArticleDetailsPage />
    </AppLayout>
);
// => System Configuration
const SystemConfigElement = () => (
    <AppLayout>
        <SystemConfigPage />
    </AppLayout>
);
// => Profile
const ProfileElement = () => (
    <AppLayout>
        <ProfilePage />
    </AppLayout>
);

// !############## MAIN FUNC ###########
const App = () => {
    return (
        <React.Fragment>
            <Routes>
                {/* ################## GUEST ROUTES ################## */}
                <Route element={<GuestRoutes />}>
                    <Route path="/" element={<LoginElement />} />
                </Route>

                {/* ################## PROTECTED ROUTES ################## */}
                <Route element={<PrivateRoutes />}>
                    <Route path="/dashboard" element={<DashboardElement />} />
                    <Route path="/profile" element={<ProfileElement />} />
                    <Route
                        path="/our-gallery"
                        element={<OurGalleryElement />}
                    />
                    <Route
                        path="/daily-reflections"
                        element={<DailyReflectionsElement />}
                    />
                    <Route
                        path="/daily-reflections/:reflection_id"
                        element={<DailyReflectionDetailsElement />}
                    />
                    <Route path="/articles" element={<ArticlesElement />} />
                    <Route
                        path="/articles/:article_id"
                        element={<ArticleDetailsElement />}
                    />
                    <Route
                        path="/system-configurations"
                        element={<SystemConfigElement />}
                    />
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default App;
