import * as React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Box, Stack, Tooltip, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../assets/utils/colors";
import { NewspaperSharp, WidgetsOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useDate } from "../hooks/useDate";

// ################# CONSTANT ###############
const drawerWidth = 250;

// ################# STYLED APPBAR ###############
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    boxShadow: "none",
    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(49, 180, 76, .5), rgb(49, 180, 76, .2))`,
}));

// ################# TOPBAR RIGHT ###############
const TopbarRight = styled(Stack)({});

// !###################### MAIN FUNC ############################
const TopBar = ({ mobileOpen, handleDrawerToggle }) => {
    // -> date
    const date = useDate();

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    width: {
                        xs: "100%",
                        sm: true
                            ? `calc(100% - ${drawerWidth}px)`
                            : `calc(100% - ${drawerWidth}px)`,
                    },
                    background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                }}
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    {/* ################### LEFT ################# */}
                    <Box
                        sx={
                            {
                                // display: "flex",
                                // alignItems: "center",
                                // justifyContent: "center",
                            }
                        }
                    >
                        <Typography
                            noWrap
                            component="div"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            Welcome back
                        </Typography>
                        <Typography
                            noWrap
                            component="div"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 12,
                            }}
                        >
                            To your dashboard
                        </Typography>
                    </Box>
                    <Box></Box>

                    {/* ################### RIGHT ################# */}
                    <TopbarRight
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {/* <Link to={"/articles"}>
                            <Tooltip title={"articles"} arrow>
                                <IconButton
                                    color="info"
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                                    }}
                                >
                                    <NewspaperSharp />
                                </IconButton>
                            </Tooltip>
                        </Link> */}
                        <Stack variant="div" style={{ display: "flex" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: 2,
                                    p: 1,
                                    width: 100,
                                    backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.bgColor5,
                                        fontWeight: "bold",
                                    }}
                                >
                                    {date.time}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 11,
                                        color: colors.warning,
                                    }}
                                >
                                    {date.date}
                                </Typography>
                            </Box>
                        </Stack>
                        {mobileOpen ? (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                }}
                            >
                                <CloseIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={handleDrawerToggle}
                                color="inherit"
                                size="small"
                                sx={{
                                    display: { xs: "block", sm: "none" },
                                }}
                            >
                                <WidgetsOutlined sx={{ fontSize: 30 }} />
                            </IconButton>
                        )}
                    </TopbarRight>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default TopBar;
