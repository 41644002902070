import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    articles: [],
    articleDetails: null,
};

// !########## SLICE #############
const articlesSlice = createSlice({
    name: "articlesSlice",
    initialState,
    reducers: {
        setArticles: (state, actions) => {
            state.articles = actions.payload;
        },
        setArticleDetails: (state, actions) => {
            state.articleDetails = actions.payload;
        },
    },
});

export const { setArticles, setArticleDetails } = articlesSlice.actions;
export default articlesSlice.reducer;
