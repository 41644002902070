import React from "react";
import { Avatar, Box, Grid, Typography, styled } from "@mui/material";
import logo from "../assets/media/images/logo.png";
import Loader from "react-js-loader";
import { colors } from "../assets/utils/colors";

// ########## MUI Custom ############
const PageBgImageCon = styled(Box)({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
    zIndex: -1,
});

const FullAppLoader = () => {
    return (
        <>
            <Box>
                <PageBgImageCon>
                    <Box
                        sx={{
                            position: "fixed",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // background: `linear-gradient(180deg, ${colors.bgColor5}, ${colors.primary})`,
                        }}
                        // className="animated-bg-2"
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                            }}
                        >
                            <Box>
                                <Avatar
                                    variant="square"
                                    src={logo}
                                    sx={{
                                        height: 100,
                                        width: 100,
                                        mb: -2,
                                        img: {
                                            objectFit: "contain",
                                        },
                                    }}
                                />
                                <Loader
                                    type="box-up"
                                    bgColor={colors.primary}
                                    color={colors.primary}
                                    size={100}
                                />
                            </Box>
                        </Box>
                    </Box>
                </PageBgImageCon>
            </Box>
        </>
    );
};

export default FullAppLoader;
