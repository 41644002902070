import React, { useState } from "react";
import PageLayout from "../layouts/PageLayout";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import { colors } from "../../assets/utils/colors";
import { useDispatch, useSelector } from "react-redux";
import useRTK from "../../hooks/useRTK";
import { dailyReflectionSelector } from "../../state/features/selectors";
import CustomDialog from "../../components/dialogs/CustomDialog";
import { isEmpty } from "lodash";
import NoContent from "../../components/NoContent";
import UpdateReflection from "./CRUD/UpdateReflection";
import { useGetSingleDailyReflectionsQuery } from "../../api/dailyReflection/dailyReflectionAPI";
import { setDailyReflectionDetails } from "../../state/features/dailyReflection/dailyReflectionSlice";
import { useParams } from "react-router-dom";
import DeleteReflection from "./CRUD/DeleteReflection";
import ActivateDeactivateReflection from "./CRUD/ActivateDeactivateReflection";
import { EditTwoTone } from "@mui/icons-material";
import moment from "moment";

const DailyReflectionsPage = () => {
    // ############# Const ##############
    const RTK = useRTK();
    const dispatch = useDispatch();
    const { reflection_id } = useParams();

    // ############# Comp State ############
    const [dialogOpen, setDialogOpen] = useState(false);

    // ############ Redux state #################
    const { reflectionDetails } = useSelector(dailyReflectionSelector);

    // ############# FUNC ###############
    const handleDialogyOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogyClose = () => {
        setDialogOpen(false);
    };

    // ############# RTK ##################
    const {
        isLoading,
        isSuccess,
        data: responseData,
    } = useGetSingleDailyReflectionsQuery(reflection_id);

    // ############# RTK response ##################
    const rtkActions = (data) => {
        dispatch(setDailyReflectionDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkActions);

    return (
        <>
            {/* ########### Dialog ############## */}
            <CustomDialog
                head={"Update article"}
                openDialog={dialogOpen}
                closeDialog={handleDialogyClose}
            >
                <UpdateReflection
                    reflection={reflectionDetails}
                    handleClose={handleDialogyClose}
                />
            </CustomDialog>

            <PageLayout
                pageTitle={"Daily reflections details"}
                isLoading={isLoading}
            >
                {isEmpty(reflectionDetails) ? (
                    <>
                        <NoContent message={"Reflection details not found"} />
                    </>
                ) : (
                    <Box
                        sx={{
                            background: `linear-gradient(360deg, ${colors.bgColor2}, ${colors.bgColor2})`,
                            borderRadius: 5,
                            p: 5,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                position: "relative",
                                display: "flex",
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <Box
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            p: 5,
                                            textAlign: "center",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 25,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                                opacity: 0.8,
                                            }}
                                        >
                                            {reflectionDetails?.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: 20,
                                                fontWeight: "bold",
                                                background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                                backgroundClip: "text",
                                                textFillColor: "transparent",
                                                mt: 2,
                                            }}
                                        >
                                            {moment(
                                                reflectionDetails?.date
                                            ).format("LL")}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item sm={6}>
                                    <Box
                                        className="img1"
                                        sx={{
                                            height: 400,
                                            width: "100%",
                                            position: "relative",
                                            borderRadius: 10,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Avatar
                                            src={reflectionDetails?.image}
                                            variant="square"
                                            sx={{
                                                height: "100%",
                                                width: `100%`,
                                                borderRadius: 5,
                                                img: {
                                                    objectFit: "cover",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box
                            sx={{
                                p: { md: 5, sm: 5, xs: 2 },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    lineHeight: 1.6,
                                    opacity: 0.8,
                                    background: `linear-gradient(rgba(208, 0, 0, 1), rgba(250, 163, 7, 1))`,
                                    backgroundClip: "text",
                                    textFillColor: "transparent",
                                    textAlign: "center",
                                }}
                            >
                                {reflectionDetails?.body}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height: 50,
                                mt: -1,
                                borderBottomLeftRadius: 10,
                                borderBottomRightRadius: 10,
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                pt: 1,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            }}
                        >
                            <ActivateDeactivateReflection
                                status={reflectionDetails.status}
                                id={reflectionDetails.id}
                            />
                            <IconButton
                                size="small"
                                sx={{
                                    p: 1,
                                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                }}
                                onClick={() => {
                                    handleDialogyOpen();
                                }}
                            >
                                <EditTwoTone
                                    color="warning"
                                    sx={{ fontSize: 20 }}
                                />
                            </IconButton>
                            <DeleteReflection reflection={reflectionDetails} />
                        </Box>
                    </Box>
                )}
            </PageLayout>
        </>
    );
};

export default DailyReflectionsPage;
