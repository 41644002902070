import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import { colors } from "../assets/utils/colors";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import logo from "../assets/media/images/logo.png";
import {
    AdminPanelSettings,
    ArrowRight,
    CampaignOutlined,
    Groups2,
    Home,
    LocalPoliceOutlined,
    LockClockOutlined,
    LogoutOutlined,
    ManageAccounts,
    NewspaperSharp,
    Payment,
    Person2,
    PictureInPicture,
    Settings,
} from "@mui/icons-material";
import useRTK from "../hooks/useRTK";
import useAuth from "../hooks/useAuth";
import { useLogoutMutation } from "../api/auth/authAPI";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../state/features/selectors";

// ############### CONSTANTS #################
const drawerWidth = 250;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    border: "none !important",
    padding: `0px !important`,
    background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.bgColor1})`,
});
const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

// ############### STYLED DESKTOP DRAWER #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    boxShadow: "none",
    border: "none !important",
    "& .MuiPaper-root": {
        backgroundColor: colors.primary,
    },
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    paddingTop: 0,
    transition: "all ease-in-out 0.3s",
    "&::before": {
        content: '""',
        position: "absolute",
        height: 0,
        bottom: "50%",
        width: 4,
        transition: "all ease-in-out 0.3s",
        backgroundColor: colors.primary,
    },
    "& .icon": {
        minWidth: 0,
        justifyContent: "center",
        color: colors.primary,
        opacity: 0.9,
    },
    "& .name": {
        color: colors.primary,
    },
}));

//! #################### MAIN FUNC ######################
const SideBar = ({ handleDrawerToggle, mobileOpen }) => {
    // ############ CONST ###################
    const RTK = useRTK();
    const auth = useAuth();
    const [open, setOpen] = React.useState(true);

    // ########### Redux state ################
    const { user } = useSelector(authSelector);

    const { window } = "props";
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ############## RTK ######################
    const [
        logoutUser,
        { isLoading, isSuccess, isError, error, data: logoutData },
    ] = useLogoutMutation();
    // ############# useEffect ##################
    const rtkActions = (data) => {
        auth.logout(data);
    };
    RTK.useRTKResponse(isSuccess, isError, error, logoutData, rtkActions);

    // ################# DRAWER CONTENT ################
    // -> Nav links
    let links = [
        {
            id: 1,
            name: "Dashboard",
            icon: <Home />,
            url: "/dashboard",
            tooltip: "dashboard",
        },
        {
            id: 2,
            name: "Gallery",
            icon: <PictureInPicture />,
            url: "/our-gallery",
            tooltip: "gallery",
        },
        {
            id: 4,
            name: "Articles",
            icon: <NewspaperSharp />,
            url: "/articles",
            tooltip: "articles",
        },
        {
            id: 3,
            name: "Daily reflections",
            icon: <LockClockOutlined />,
            url: "/daily-reflections",
            tooltip: "reflections",
        },
        // {
        //     id: 7,
        //     name: "System configuration",
        //     icon: <Settings />,
        //     url: "/system-configurations",
        //     tooltip: "system configuration",
        // },
    ];
    const drawer = (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                }}
            >
                <Box
                    sx={{
                        height: 220,
                        borderBottomRightRadius: 50,
                        borderBottomLeftRadius: 50,
                        mt: -0.1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        // backgroundImage: `linear-gradient(rgba(23, 148, 202, .5), rgba(23, 148, 202, .5)), radial-gradient(circle at left top, rgb(1, 110, 218), rgb(49, 180, 76, .5))`,
                        background: `linear-gradient(90deg, ${colors.primary}, ${colors.primary})`,
                    }}
                    // className="animated-bg"
                >
                    <Avatar
                        src={logo}
                        sx={{
                            height: 100,
                            width: 100,
                            mb: 2,
                            img: {
                                objectFit: "contain",
                            },
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            borderRadius: 2,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 12,
                                    sm: 12,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            St. paul's
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 12,
                                    sm: 12,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            Senior seminary
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 12,
                                    sm: 12,
                                    xs: 10,
                                },
                                textTransform: "capitalize",
                                color: colors.bgColor1,
                                textAlign: "center",
                            }}
                            noWrap
                        >
                            Kipalapala - Tabora, Tanzania
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    md: 12,
                                    sm: 12,
                                    xs: 10,
                                },
                                textTransform: "uppercase",
                                color: colors.bgColor1,
                                textAlign: "center",
                                background: `linear-gradient(${colors.bgColor1}, ${colors.tertiary})`,
                                backgroundClip: "text",
                                textFillColor: "transparent",
                                fontWeight: "bold",
                                mt: 1,
                            }}
                            noWrap
                        >
                            Admin dashboard
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container justifyContent={"center"}>
                        <Grid item sm={11} xs={11}>
                            {links.map((link) => (
                                <NavLink to={link.url} key={link.id}>
                                    {({ isActive }) => (
                                        <SideNavListItem
                                            disablePadding
                                            sx={{
                                                display: "block",
                                                "&::before": isActive && {
                                                    content: '""',
                                                    position: "absolute",
                                                    height: "100%",
                                                    bottom: 0,
                                                    top: 0,
                                                    width: 4,
                                                    transition:
                                                        "all ease-in-out 0.3s",
                                                    backgroundColor:
                                                        colors.primary,
                                                },
                                                "&:hover": !isActive && {
                                                    transition:
                                                        "all ease-in-out 0.3s",
                                                    "&::before": {
                                                        transition:
                                                            "all ease-in-out 0.3s",
                                                        height: "100%",
                                                        bottom: 0,
                                                    },
                                                },
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    py: 0.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    className="icon"
                                                    sx={{
                                                        mr: open ? 1 : "auto",
                                                    }}
                                                >
                                                    {link.icon}
                                                </ListItemIcon>
                                                <ListItemText
                                                    className="name"
                                                    primary={link.name}
                                                    sx={{
                                                        opacity: open ? 1 : 0,
                                                    }}
                                                    primaryTypographyProps={{
                                                        fontSize: 14,
                                                        fontWeight: "bold",
                                                    }}
                                                />
                                                <Badge
                                                    className="badge"
                                                    badgeContent={link.total}
                                                    showZero
                                                    sx={{
                                                        "& .MuiBadge-badge": {
                                                            bgcolor:
                                                                colors.bgColor3,
                                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                                            color: colors.warning,
                                                            fontWeight: "bold",
                                                        },
                                                    }}
                                                />
                                            </ListItemButton>
                                        </SideNavListItem>
                                    )}
                                </NavLink>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            p: 1,
                            height: 110,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderTopRightRadius: 20,
                            borderTopLeftRadius: 20,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                        }}
                    >
                        <Grid container>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Avatar
                                        src=""
                                        sx={{
                                            height: 50,
                                            width: 50,
                                            border: `1px dotted ${colors.primary}`,
                                            background: `linear-gradient(360deg, ${colors.bgColor1}, ${colors.primary})`,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: 10,
                                            fontWeight: "bold",
                                            mt: 1,
                                            color: colors.primary,
                                        }}
                                    >
                                        {user?.name}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={6}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    {/* <Tooltip
                                        arrow
                                        title={"Profile"}
                                        placement="right"
                                    >
                                        <Link to="/profile">
                                            <Button
                                                size="small"
                                                color="primary"
                                                startIcon={<ManageAccounts />}
                                                endIcon={<ArrowRight />}
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                Profile
                                            </Button>
                                        </Link>
                                    </Tooltip> */}
                                    <Tooltip
                                        arrow
                                        title={"logout"}
                                        placement="right"
                                    >
                                        <Button
                                            size="small"
                                            color="error"
                                            startIcon={<Person2 />}
                                            endIcon={<LogoutOutlined />}
                                            sx={{ mt: 1, fontWeight: "bold" }}
                                            disabled={isLoading}
                                            onClick={() => {
                                                logoutUser();
                                            }}
                                        >
                                            Logout
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );

    return (
        <>
            {/* ##################### mobile ################# */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", sm: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "100%", // Change the width of drawer in mobile
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar
                    sx={{ display: { md: "none", sm: "none", xs: "block" } }}
                />
                {drawer}
            </Drawer>

            {/* ##################### desktop ################ */}
            <DrawerDesktop
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: "none", sm: "block" },
                }}
            >
                {/* <AppBarHeight /> */}
                {/* ========== Drawer desk toggle button ======== */}
                {/* <DrawerToggleBtn
                    sx={{
                        position: 'absolute',
                        top: 60,
                        zIndex: 1098,
                        right: -5,
                    }}
                >
                    {open ? (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerClose}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerOpen}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    )}
                </DrawerToggleBtn> */}
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default SideBar;
