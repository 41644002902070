// => GET all gallaries
const GET_ALL = '/gallaries';

// => GET single package
const GET_BY_ID = '/gallaries';

// => CREATE package
const CREATE = '/gallaries';

// => UPDATE package
const UPDATE = '/gallaries';

// => ACTIVATE package
const ACTIVATE = '/gallaries/activate';

// => DEACTIVATE package
const DEACTIVATE = '/gallaries/deactivate';

// => DELETE package
const DELETE = '/gallaries';

export const gallariesEndpoints = {
    GET_ALL,
    GET_BY_ID,
    CREATE,
    UPDATE,
    ACTIVATE,
    DEACTIVATE,
    DELETE,
};
